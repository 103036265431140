import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  customFetch,
  customFetchToken,
  setAuthToken,
} from "../customFetch/customFetch";
import axios from "axios";
import { toast } from "react-toastify";
import * as momentTz from "moment-timezone";

const initialState = {
  student: {},
  teacher: {},
  user: {},
  logout: true,
  showBeta: true,
  forgotPassword: false,
  redirectLink: "",
  accessToken: "",
  geolocation: {
    latitude: 0,
    longitude: 0,
  },
  showCourses: false,
  refreshMessages: false,
  refreshUser: false,
  timezone: momentTz.tz.guess(),
};

export const setPaymentMethod = createAsyncThunk(
  "setPaymentMethod",
  async (item, thunkApi) => {
    let url = `/users/paymentInfo`;
    try {
      const resp = await customFetchToken.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const subscribeToNewsLetter = createAsyncThunk(
  "subscribeToNewsLetter",
  async (email, thunkApi) => {
    let url = `/subscribe`;
    try {
      const resp = await customFetchToken.post(url, { email });
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updatePaymentMethod = createAsyncThunk(
  "updatePaymentMethod",
  async (item, thunkApi) => {
    let url = `/users/paymentInfo`;

    try {
      const resp = await customFetchToken.patch(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const addGeoLocation = createAsyncThunk(
  "addGeoLocation",
  async (item, thunkApi) => {
    let url = `/users/geoLocation`;
    try {
      const resp = await customFetch.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const getPaymentMethod = createAsyncThunk(
  "getPaymentMethod",
  async (item, thunkApi) => {
    let url = `/users/paymentInfo`;
    try {
      const resp = await customFetchToken.get(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getUserTransactions = createAsyncThunk(
  "getUserTransactions",
  async (params, thunkApi) => {
    let url = `/users/wallet`;
    try {
      const resp = await customFetchToken.get(url, {
        params,
      });
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updateUserHelpGuide = createAsyncThunk(
  "updateUserHelpGuide",
  async (guide, thunkApi) => {
    let url = `/users/guide`;
    try {
      const resp = await customFetchToken.put(url, { guide: guide });
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const forgotPass = createAsyncThunk(
  "forgotPass",
  async (email, thunkApi) => {
    let url = `/users/forget-password`;
    try {
      const resp = await customFetch.post(url, email);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const verifyUserCode = createAsyncThunk(
  "verifyCode",
  async (item, thunkApi) => {
    let url = `/users/verifyCode`;
    try {
      const resp = await customFetch.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const createPassword = createAsyncThunk(
  "createPassword",
  async (item, thunkApi) => {
    let url = `/users/create-password`;
    try {
      const resp = await customFetch.put(url, item);
      if (!resp.data?.user?.student?.id) {
        return;
      }
      const { accessToken, refreshToken } = resp.data;

      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
      localStorage.setItem("user", JSON.stringify(resp?.data?.user));
      localStorage.setItem("loggedIn", true);
      localStorage.setItem(
        "studentId",
        JSON.stringify(resp.data.user.student.id)
      );

      setAuthToken(accessToken);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  }
);
export const verifyUser = createAsyncThunk(
  "verifyUser",
  async (item, thunkApi) => {
    let url = `/users/verify`;
    try {
      const resp = await customFetch.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const resendOTP = createAsyncThunk(
  "resendOTP",
  async (item, thunkApi) => {
    let url = `/users/resendOTP`;
    try {
      const resp = await customFetch.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const resendCode = createAsyncThunk(
  "resendCode",
  async (item, thunkApi) => {
    let url = `/users/resendCode`;
    try {
      const resp = await customFetch.post(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const updateUser = createAsyncThunk(
  "updateUser",
  async (item, thunkApi) => {
    let url = `/users/${item.id}`;
    try {
      const resp = await customFetchToken.patch(url, item.user);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getUserById = createAsyncThunk(
  "getUserById",
  async (id, thunkApi) => {
    let url = `/users/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const resetWithToken = createAsyncThunk(
  "resetWithToken",
  async (item, thunkApi) => {
    let url = `/users/reset-password`;
    try {
      const resp = await customFetch.put(url, item);
      return resp;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const register = createAsyncThunk("register", async (user, thunkApi) => {
  let url = `/users/register`;
  try {
    const resp = await customFetch.post(url, user);
    return resp.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.message);
  }
});
export const registerAdmin = createAsyncThunk(
  "registerAdmin",
  async (admin, thunkApi) => {
    let url = `/users/registerAdmin`;
    try {
      const resp = await customFetchToken.post(url, admin);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const getChats = createAsyncThunk(
  "getChats",
  async (userId, thunkApi) => {
    let url = `/chats/${userId}`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const getContacts = createAsyncThunk(
  "getContacts",
  async (item, thunkApi) => {
    let url = `/chats/`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);

export const createAChat = createAsyncThunk(
  "createAChat",
  async (item, thunkApi) => {
    let url = `/chats`;
    try {
      const resp = await customFetch.post(url, item);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const logout = createAsyncThunk("logout", async (_, thunkApi) => {
  let url = `/users/logout`;
  try {
    const resp = await customFetch.get(url);
    thunkApi.dispatch(userSlice.actions.logoutUser());
    // window.location.reload();

    return resp.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data.message);
  }
});
export const login = createAsyncThunk("login", async (user, thunkApi) => {
  let url = `/users/login`;
  try {
    const resp = await customFetch.post(url, user);
    const { accessToken, refreshToken } = resp.data;
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
    localStorage.setItem("user", JSON.stringify(resp?.data?.user));
    localStorage.setItem("loggedIn", true);
    if (resp.data.user.role === "TEACHER") {
      localStorage.setItem("teacher", JSON.stringify(resp.data.user.teacher));
    } else if (resp.data.user.role === "STUDENT") {
      localStorage.setItem(
        "studentId",
        JSON.stringify(resp.data.user.student.id)
      );
    }
    thunkApi.dispatch(setAccessToken(accessToken));
    setAuthToken(accessToken);
    return resp.data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response);
  }
});
export const getUserChatMessages = createAsyncThunk(
  "getUserChatMessages",
  async (id, { rejectWithValue }) => {
    let url = `/chats/chatMessages/${id}`;
    try {
      const resp = await customFetchToken.get(url);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getMessageUnseenCount = createAsyncThunk(
  "getUserChatMessages",
  async (_, { rejectWithValue }) => {
    let url = `/chats/unSeenCount/`;
    try {
      const resp = await customFetchToken.post(url);
      return resp.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const loginWithCode = createAsyncThunk(
  "login",
  async (user, thunkApi) => {
    let url = `/users/loginWithCode`;
    try {
      const resp = await customFetch.post(url, user);
      const { accessToken, refreshToken } = resp.data;
      localStorage.setItem("access_token", accessToken);
      localStorage.setItem("refresh_token", refreshToken);
      localStorage.setItem("user", JSON.stringify(resp?.data?.user));
      localStorage.setItem("loggedIn", true);
      if (resp.data.user.role === "TEACHER") {
        localStorage.setItem("teacher", JSON.stringify(resp.data.user.teacher));
      } else if (resp.data.user.role === "STUDENT") {
        localStorage.setItem(
          "studentId",
          JSON.stringify(resp.data.user.student.id)
        );
      }
      setAuthToken(accessToken);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);
export const sendEmailNotification = createAsyncThunk(
  "sendEmail",
  async (message, thunkApi) => {
    let url = `/notifications/email`;
    try {
      const resp = await customFetch.post(url, message);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);
export const deleteImage = createAsyncThunk(
  "deleteImage",
  async (id, thunkApi) => {
    let url = `/images/delete/${id}`;
    try {
      const resp = await customFetch.delete(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

export const getPublicSettings = createAsyncThunk(
  "getPublicSettings",
  async (id, thunkApi) => {
    let url = `/admin/settings/public`;
    try {
      const resp = await customFetch.get(url);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data.message);
    }
  }
);
export const inviteFriends = createAsyncThunk(
  "inviteFriends",
  async (email, thunkApi) => {
    let url = `/students/inviteFriends`;
    try {
      const resp = await customFetchToken.post(url, email);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);
export const updateUserPassword = createAsyncThunk(
  "updateUserPassword",
  async (item, thunkApi) => {
    let url = `/users/change-password`;
    try {
      const resp = await customFetchToken.put(url, item.passwords);
      return resp.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
      state.logout = false;
    },
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserChange: (state, action) => {
      //
      state.user = action.payload;
    },
    redirectLink: (state, action) => {
      state.redirectLink = action.payload;
    },
    logoutUser: (state, action) => {
      state.logout = true;
    },
    loginUser: (state, action) => {
      state.logout = false;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    handleUserChange: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    resetUserState: (state) => {
      return initialState;
    },
  },
});
export const {
  redirectLink,
  setUser,
  loginUser,
  setAccessToken,
  setUserChange,
  handleUserChange,
  resetUserState,
} = userSlice.actions;

export default userSlice.reducer;
