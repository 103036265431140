import React, { useEffect, useState } from "react";
import PageHeader from "../PageHeader/PageHeader";
import "./BigContainer.styles.scss";
import PageDescription from "../../Components/PageDescription/PageDescription";
import LessonDescription from "../../Components/LessonDescription/LessonDescription";
import customFetch from "../../../ReduxToolkit/customFetch/customFetch";
import TeacherAvailability from "../TeacherAvailability/TeacherAvailability";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import TeacherMediaSection from "../TeacherMediaSection/TeacherMediaSection";
import {
  handleLessonChange,
  selectLesson,
} from "../../../ReduxToolkit/Slices/lessonsSlice";
import Reviews from "../Reviews/Reviews";
import Review from "../Review/Review";

export default function BigContainer({ videos, images, teacher, lessons }) {
  const [index, setIndex] = useState(0);
  const [teacherAvailability, setTeacherAvailability] = useState([]);
  const { id, subject } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      lessons[index]?.isGroup && navigate(`/groupLessons/${lessons[index].id}`);
    }, 100); // Replace 1000 with your desired timeout in milliseconds
    return () => clearTimeout(timeoutId); // This cleans up the timeout if the component unmounts or if the index changes before the timeout fires
  }, [index]);
  useEffect(() => {
    if (subject) {
      lessons.map((lesson, i) => {
        if (lesson.title === subject) {
          return setIndex(i);
        }
      });
    } else {
      lessons.map((lesson, i) => {
        if (!lesson.isGroup) {
          return setIndex(i);
        }
      });
    }
  }, [subject, lessons]);

  const subjects = lessons.length
    ? lessons.map((lesson) => lesson.subjects[0].name)
    : [];

  useEffect(() => {
    customFetch(`/teachers/${id}/availability`).then((res) => {
      setTeacherAvailability(res.data);
    });
  }, [id]);

  const onBookingClick = () => {
    dispatch(handleLessonChange({ name: "FlexibleDates", value: [] }));
    dispatch(
      selectLesson({
        lesson: lessons[index].id,
        lessonDetails: lessons[index],
        teacher,
        lessonName: lessons[index]?.title,
        location: lessons[index]?.location,
      })
    );
    navigate(`/packages/${lessons[index].id}`);
  };

  return (
    <div className="big__container">
      <div className="left">
        {teacher && !lessons[index]?.isGroup && (
          <PageHeader
            profilePicture={teacher && teacher.user?.profileImage?.url}
            name={teacher?.user?.name}
            lastName={teacher?.user?.lastName}
            position="Teacher"
            location={lessons[index]?.location}
            price={"teacher.hourlyRate"}
            rating={teacher?.rating.toFixed(2)}
            totalReviews={teacher?.reviews?.length}
            teacher={teacher}
            lesson={lessons[index]}
            onBookingClick={onBookingClick}
            pet={teacher?.pets}
          />
        )}
        <TeacherMediaSection video={videos} images={images} />

        {/* {lessons.length && <LessonDescription lesson={lessons[index]} />} */}

        <PageDescription
          name={teacher && teacher.user.name + " " + teacher.user.lastName}
          img={teacher && teacher.user?.profileImage?.url}
          description={teacher && teacher.description}
          teacher={teacher}
          level={lessons[index]?.level}
        />
        <Reviews reviews={teacher.reviews} rating={teacher.rating} />
        <Recommendations teacher={teacher} />
      </div>
      {lessons.length && !lessons[index]?.isGroup && (
        <TeacherAvailability
          hourRate={teacher && teacher?.hourlyRate}
          locations={lessons[index]?.location}
          subjects={subjects}
          index={index}
          setIndex={setIndex}
          onBookingClick={onBookingClick}
          teacher={teacher}
          lessons={lessons}
          lesson={lessons[index]}
          availability={teacherAvailability?.length ? teacherAvailability : []}
        />
      )}
    </div>
  );
}

export const Recommendations = ({ teacher }) => {
  return (
    <>
      {!!teacher?.recommendations?.length && (
        <div className="tutor__page__description">
          <h4>Recommendations</h4>
          <div className="description">
            <div className="teacher_recommendations_map">
              {teacher?.recommendations?.length &&
                teacher?.recommendations.map((rec, i) => (
                  <Review
                    image={rec?.profileImage?.url}
                    name={rec?.name}
                    lastName={rec?.lastName}
                    description={rec?.feedback}
                    // position={"asd"}
                    rate={rec?.rating}
                    key={i}
                    className={"teacher_recommendations_container"}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
